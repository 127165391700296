import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import styles from "./css/form.module.css";
import { Successs } from "../Overlay/Successs";
import Nike from "../../assets/nike.png";

export const Form = () => {
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const schema = yup.object().shape({
        yourName: yup.string().required("Your name is required!"),
        company: yup.string().required("Company is required!"),
        email: yup.string().email("Email address is not valid").required("Email address is required"),
        phoneNumber: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone number is required"),
        message: yup.string().min(10).max(200).required("Message is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        setIsSuccessOpen(!isSuccessOpen);
        setTimeout(() => {
            window.location.reload(false);
        }, 2000);
    };

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder="Your name" {...register("yourName")} />
                <p>{errors.yourName?.message}</p>
                <input type="text" placeholder="Company" {...register("company")} />
                <p>{errors.company?.message}</p>
                <input type="email" placeholder="Email address" {...register("email")} />
                <p>{errors.email?.message}</p>
                <input type="tel" placeholder="Phone number" {...register("phoneNumber")} />
                <p>{errors.phoneNumber?.message}</p>
                <textarea placeholder="message" {...register("message")} />
                <p>{errors.message?.message}</p>
                <input type="submit" value="SEND" />
            </form>
            <Successs isOpen={isSuccessOpen} onClose={() => setIsSuccessOpen(!isSuccessOpen)}>
                <div className={styles.successRow}>
                    <img src={Nike} alt="nike" />
                    <h1>SUCCESS!</h1>
                </div>
                <p>
                    Message has been sent
                </p>
            </Successs>
        </div>
    );
};