import styles from "./css/political.module.css";
import Work from "../assets/work.jfif";
import Iphone from "../assets/femi.png";
import Board from "../assets/board.png";
import Politician from "../assets/politician.png";
import PhoneAddict from "../assets/phone_addict.png";
import Helmet from "react-helmet";
import { useContext } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";


export const Political = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Political Sector - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.pageOne}>
                <h1>
                    <span style={{ color: "#1972F9" }}>POLITICAL</span> INDUSTRY
                </h1>
                <p>
                    Unlock the Power of Political Text Messaging, Connect with Voters Like Never Before
                    <br /><br />
                    In today's fast-paced world, reaching voters is crucial for any campaign
                </p>
                <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                    <button>Try it on</button>
                </Link>
            </div>

            <div className={styles.menuOne}>
                <div className={styles.menuOneContent}>
                    <h1> WHY<span style={{ color: "#1972F9" }}> POLITICAL SMS MARKETING</span> IS A GAME CHANGER:
                    </h1>
                    <p>
                        Instant Reach: SMS messages get read fast. They're like a direct line to voters' pockets, ensuring your message gets noticed right away. <br /><br />

                        Personal Touch: Make every message count by addressing voters personally. When people feel like you're talking to them, they're more likely to listen. <br /><br />

                        Targeted Messaging: Send the right message to the right people. Whether it's young voters, seniors, or anyone in between, tailor your messages to speak their language. <br /><br />
                        Get-Out-The-Vote: Remind voters about important dates and locations. Every vote counts, so make sure they know where and when to cast theirs.  Instant Reach: SMS messages get read fast. They're like a direct line to voters' pockets, ensuring your message gets noticed right away.
                    </p>

                    <div className={styles.contentImage}>
                        <img src={Work} alt="work" />
                    </div>
                </div>

                <div className={styles.menuOneImage}>
                    <img src={Iphone} alt="iphone" />
                </div>
            </div>

            <div className={styles.cardOne}>
                <div className={styles.cardImage}>
                    <img src={Board} alt="board" />
                </div>
                <div className={styles.cardContent}>
                    <h1>
                        ADVANTAGES
                        <span style={{ color: "#1972F9" }}> OF POLITICAL SMS MARKETING</span>
                    </h1>
                    <p>
                        Get Feedback: Find out what voters really think with quick surveys. Their input can help shape your campaign.
                        <br /><br />
                        Save Money: SMS marketing is budget-friendly. You can reach lots of voters without breaking the bank.
                        <br /><br />
                        Reach Everyone: Even in remote areas, where other forms of communication might not reach, SMS gets through.
                        <br /><br />
                        Work Together: Combine SMS with other methods like social media for a campaign that covers all the bases.
                    </p>
                </div>
            </div>

            <div className={styles.cardTwo}>
                <div className={styles.cardTwoSideA}>
                    <h1>
                        Put Political Text Messaging to Work:
                    </h1>

                    <div className={styles.sideAImage}>
                        <img src={Politician} alt="politician" />
                    </div>

                </div>
                <div className={styles.cardTwoSideB}>
                    <ul className={styles.bList}>
                        <li>Mobilize Voters</li>
                        <li>Raise Funds</li>
                        <li>Stay Updated</li>
                        <li>Recruit Volunteers</li>
                        <li>Hear from Voters </li>
                        <li>Speak Up for Causes</li>
                    </ul>
                </div>

            </div>

            <div className={styles.cardThree}>
                <div className={styles.miniCardThree}>
                    <div className={styles.mctContent}>
                        <h1>USES <span style={{ color: "#1972F9" }}> OF SENDING POLITICAL TEXT MESSAGES</span></h1>
                        <ul className={styles.cardThreeList}>
                            <li> . Voter mobilization</li>
                            <li> . Fundraising and donation</li>
                            <li> . Real-time updates</li>
                            <li> . Volunteer recruitment</li>
                            <li> . Feedbacks and surveys</li>
                            <li> . Issue advocacy</li>
                        </ul>
                    </div>
                    <div className={styles.mctImage}>
                        <img src={PhoneAddict} alt="phone_addict" />
                    </div>
                </div>
            </div>

            <div className={styles.cardFour}>
                <h1>SMS POLITICAL <span style={{ color: "#1972F9" }}>CAMPAIGN EXAMPLES </span></h1>

                <div className={styles.cardRowA}>
                    <div className={styles.miniRow}>
                        <p>
                            Fundraising: Every bit helps! Can we count on your support? Text 'DONATE' to chip in and make a difference today.<br /><br />
                            Thank you for standing with us! 🙌🇳🇬
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            Introduction: Hey there! I'm [Name], and I'm running to make a change in our community. Got ideas or concerns? I'm all ears. Let's chat!
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            Youth Forum Invite: Calling all young voices! Join us for a chat about the issues that matter most. Come share your thoughts and let's make a difference together!
                        </p>
                    </div>
                </div>

                <div className={styles.cardRowA}>
                    <div className={styles.miniRow}>
                        <p>
                            Election Reminder: Hey [Name], don't forget to vote on [Date]! Every voice counts, including yours. Let's shape the future of our community together.
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            📚 Education Focus: Hey students! Education is key to a brighter future. Let's make sure everyone has access to quality learning. Reply 'EDUCATION' to show your support!
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            Healthcare Focus: Healthy communities start with quality healthcare. Let's work together to ensure everyone gets the care they deserve. Reply 'HEALTH' to join the cause!
                        </p>
                    </div>
                </div>

                <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                    <button>Get Started</button>
                </Link>
            </div>
        </div>
    )
}

