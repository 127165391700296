import styles from "./css/login.module.css";
import { useContext } from "react";
import { AppContext } from "../App";
import Logo from "../assets/logo.png";
import Girl from "../assets/girl.png";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";
import { POST } from "../services/backend";

export const Login = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({});
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState("");
    setShowHead(true);
    setShowFoot(true);
    const navigate = useNavigate();

    const login = async (e) => {
        e.preventDefault();

        const endpoint = "/login";

        const body = {
            "email": email,
            "password": password
        }
        const headers = { 'Content-Type': 'application/vnd.api+json', }

        const response = await POST(endpoint, body, headers);
        if (response) {
            if (response.data.code === 200) {
                setDisplaySuccess(true);
                setSuccessMessage('logged in successfully');

                setTimeout(() => {
                    navigate("/Dashboard");
                }, 1500);

                sessionStorage.setItem("token", response.data.data.token);
                sessionStorage.setItem("isAuthenticated", true);
                sessionStorage.setItem("LinkId", response.data.data.id);
            }
            else {
                console.log(response);

                setDisplayError(true);
                setError(response.data.message);

                setTimeout(() => {
                    setDisplayError(false)
                }, 2500);
            }
        } else {
            setDisplayError(true);
            setError('No response from server');
            setTimeout(() => {
                setDisplayError(false)
            }, 2500);
        }


    }

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.card}>
                <ErrorNotification open={displayError} content={error} />
                <SuccessNotification open={displaySuccess} content={successMessage} />

                <div className={styles.logLogo}>
                    <img src={Logo} alt="logo" />
                </div>
                <h3><span style={{ color: "#1972F9" }}>LOG IN </span> TO YOUR ACCOUNT</h3>
                <form className={styles.form} onSubmit={(e) => login(e)}>
                    <input type="email" name="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="on" required />

                    <input type="password" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />

                    <input type="submit" value="Continue" />
                </form>
                {/* <div style={{ margin: "auto", width: "100%", maxWidth: "100%", marginBottom: "1rem" }} id="signinButton"></div> */}
                <p>
                    Don’t have an account? <Link className={styles.textLinks} to="/Signup" element={<Signup />}>Sign up</Link>
                </p>
                <p>
                    Forgot your password? <Link className={styles.textLinks} >Click here</Link>
                </p>
            </div>
            <div className={styles.logImage}>
                <img src={Girl} alt="girl" />
            </div>
        </div>
    );
}