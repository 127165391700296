import { useContext } from "react";
import { AppContext } from "../App";
import styles from "./css/contact.module.css";
import { Form } from "../components/Header/Form";
import sofDev from "../assets/sofdev.png";
import Helmet from "react-helmet";


export const Contact = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowFoot(false);
    setShowHead(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.card}>
                <h1>Contact Us, lets get started !</h1>
                <div className={styles.cardRow}>
                    <div className={styles.crImage}>
                        <img src={sofDev} alt="sofdev" />
                    </div>
                    <div className={styles.crContent}>
                        <Form />
                    </div>

                </div>
            </div>
        </div>
    );
}