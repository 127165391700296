import React from 'react';
import Logo from '../assets/logofoot.svg';
import styles from './css/foot.module.css';
import { Homepage } from '../components/Landing/Homepage';
import { Link } from 'react-router-dom';
import { Pricing } from '../pages/Pricing';
import { Political } from '../pages/Political';
import { Solution } from '../components/Header/Solution';
import { Developers } from '../pages/Developers';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                <Link className={styles.mobileLogo} to="/" element={<Homepage />}>
                    <img src={Logo} alt="logo"></img>
                </Link>
                <h3>About Us</h3>
                <p>
                    Eiffel Technology Consults (ETC) is licensed by the Nigerian Communications Commission to provide BulkSMS, Voice OTP, VAS contents, Marketing and Push Notification Integrations.
                    We are passionate about Africa's technology and growing the VAS eco-system
                </p>
                <div className={styles.row1Image}>
                    <Link className={styles.textLink} to="/" element={<Homepage />}>
                        <img src={Logo} alt="logo"></img>
                    </Link>
                </div>
            </div>

            <div className={styles.row2}>
                <h3>Quick Links</h3>
                <ul className={styles.footList}>
                    <Link className={styles.textLink} to="/Pricing" element={<Pricing />}>
                        <li>Pricing</li>
                    </Link>
                    <Link className={styles.textLink} to="/Political" element={<Political />}>
                        <li>Political</li>
                    </Link>
                    <Link className={styles.textLink} to="/Solution" element={<Solution />}>
                        <li>Solutions</li>
                    </Link>
                    <Link className={styles.textLink} to="/Developers" element={<Developers />}>
                        <li>API Integration</li>
                    </Link>
                </ul>
            </div>

            <div className={styles.row3}>
                <div className={styles.col}>
                    <h3>Contact Us</h3>
                    <ul className={styles.footList}>
                        <li>Careers</li>
                        <li>Support</li>
                    </ul>
                </div>
                <div className={styles.col}>
                    <h3>Follow us at</h3>
                    <div className={styles.thumbnails}>
                        <a href="https://www.linkedin.com/company/eiffeltechnologyconsults/" aria-label='Linkedin'
                            rel='noopener'
                            target='_blank'>
                            <i className="fa-brands fa-linkedin fa-2xl" style={{ color: "#ffffff", cursor: "pointer" }}></i>
                        </a>

                        <a href="https://www.facebook.com/officialetcng" aria-label='Facebook'
                            rel='noopener'
                            target='_blank'>
                            <i className="fa-brands fa-facebook fa-2xl" style={{ color: "#ffffff", cursor: "pointer" }}></i>
                        </a>

                        <a href="https://www.instagram.com/officialetcng/" aria-label='Instagram'
                            rel='noopener'
                            target='_blank'>
                            <i className="fa-brands fa-square-instagram fa-2xl" style={{ color: "#ffffff", cursor: "pointer" }}></i>
                        </a>

                        <a href="https://twitter.com/officialetcng" aria-label='Twitter'
                            rel='noopener'
                            target='_blank'>
                            <i className="fa-brands fa-square-twitter fa-2xl" style={{ color: "#ffffff", cursor: "pointer" }}></i>
                        </a>

                    </div>
                </div>
            </div>
        </div >
    );
};

