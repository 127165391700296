import React, { useState } from 'react';
import { MenuItems } from './MenuItems';
import { Link } from 'react-router-dom';
// import './Dropnow.css';


function Minidrop({ onClick, onClicks }) {
    // const [click, setClick] = useState(false);

    const handleClick = () => {
        onClicks();
        onClick();
    }

    return (
        <>
            <ul style={{
                listStyle: "none",
                marginTop: "0.5rem",
                marginBottom: "0rem",
                backgroundColor: "#1972f9"
            }}
            >
                {MenuItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link
                                className={item.cName}
                                to={item.path}
                                onClick={handleClick}
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    )
}

export default Minidrop;
