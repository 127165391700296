import styles from "./css/finance.module.css";
import Helmet from "react-helmet";
import ManMoney from "../assets/manmoney.png";
import Iphone from "../assets/ip2.png";
import LoanMan from "../assets/loanman.png";
import CardTwo from "../assets/cardtwo.png";
import { useContext } from "react";
import { AppContext } from "../App";
import CardThree from "../assets/cardthree.png";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";


export const Finance = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Financial Sector - ETC Bulk SMS</title>
            </Helmet>

            <div className={styles.menuOne}>
                <div className={styles.menuOneBody}>
                    <h1>FINANCIAL <span style={{ color: "#1972F9" }}>SECTOR</span></h1>
                    <p>
                        Simplify Financial Communication with BULK SMS <br /><br />
                        Connect with Your Customers, Simply
                        <br /><br />
                        Why complicate reaching your customers when you can simply send them a text? With ETC, you can communicate efficiently and effectively by reaching them directly on their phones. Here's how we help the financial sector:
                    </p>

                    <div className={styles.bodyImage}>
                        <img src={ManMoney} alt="manmoney" />
                    </div>
                </div>
                <div className={styles.menuOneImage}>
                    <img src={Iphone} alt="iphone" />
                </div>
            </div>

            <div className={styles.cardOne}>
                <h1>
                    BENEFITS <span style={{ color: "#1972F9" }}>OF TEXT MESSAGING FOR</span> FINANCE AGENCIES
                </h1>
                <div className={styles.cardRow}>

                    <div className={styles.rowImage}>
                        <img src={LoanMan} alt="loanman" />
                    </div>

                    <div className={styles.rowContent}>
                        <h1>LOANS</h1>
                        <p>
                            Reach potential customers and keep current clients engaged with texts about special loan offers or promotions.

                            Reduce missed appointments by sending reminders via text, ensuring clients are prepared.
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.cardTwo}>
                <div className={styles.cardTwoContent}>
                    <h1>
                        INSURANCE
                    </h1>
                    <p>
                        Keep policyholders informed with text reminders about policy renewals, payment due dates, or coverage changes.
                        Provide real-time updates on insurance claims status, improving customer satisfaction.
                    </p>
                </div>
                <div className={styles.cardTwoImage}>
                    <img src={CardTwo} alt="cardtwo" />
                </div>

            </div>

            <div className={styles.cardThree}>
                <div className={styles.cardThreeImage}>
                    <img src={CardThree} alt="cardthree" />
                </div>

                <div className={styles.cardThreeContent}>
                    <h1>FUNDRAISING</h1>
                    <p>
                        Raise funds for nonprofit organizations, charity events, or crowdfunding campaigns with targeted text messages. Encourage donations from supporters with clear calls-to-action and links to donation pages. Maximize Your Financial Communication:
                    </p>
                </div>
            </div>

            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.financeButton}>Get Started</button>
            </Link>

        </div >
    );
}