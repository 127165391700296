import styles from "./css/pricing.module.css";
import UnlimitedUsers from "../assets/unlimitedusers.svg";
import BetterQuote from "../assets/betterquote.svg";
import SignUp from "../assets/freesignup.svg";
import VoiceOTP from "../assets/voiceotp.svg";
import LightningDelivery from "../assets/fastdelivery.svg";
import CustomerSupport from "../assets/customersupport.svg";
import Helmet from "react-helmet";
import { useContext } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";
import { useState } from "react";


export const Pricing = () => {
    const [isSupport, setIsSupport] = useState(false);
    const Toggle = () => {
        setIsSupport(!isSupport);
    }
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pricing - ETC Bulk SMS</title>
            </Helmet>
            <h1>
                Pricing for our <span style={{ color: "#1972F9" }}> BULK SMS </span>
                marketing and business texting service
            </h1>
            <table>
                <thead>
                    <tr>
                        <th>VOLUME Plan (SMS)</th>
                        <th>Price /  SMS</th>
                        <th>Ammount (₦)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>100 SMS</td>
                        <td>1 Week trial</td>
                        <td>1 Week trial</td>
                    </tr>
                    <tr className={styles.rowOne}>
                        <td>1 - 4,999 SMS</td>
                        <td>₦3.0</td>
                        <td>₦3.0 - ₦14,990</td>
                    </tr>
                    <tr>
                        <td>5000 - 9,999 SMS </td>
                        <td>₦2.8</td>
                        <td>₦14,000 - ₦27,900</td>
                    </tr>
                    <tr className={styles.rowOne}>
                        <td>10,000 - 49,999 SMS</td>
                        <td>₦2.6</td>
                        <td>₦26,000 - ₦129,900</td>
                    </tr>
                    <tr>
                        <td>50,000 - 199,999 SMS</td>
                        <td>₦2.4</td>
                        <td>₦120,000 - ₦479,000</td>
                    </tr>
                    <tr className={styles.rowOne}>
                        <td>200,000 - 499,999 SMS</td>
                        <td>₦2.2</td>
                        <td>₦440,000 - ₦1,099,990</td>
                    </tr>
                </tbody>
            </table>

            <h2>
                Need More SMS Units? Chat us lets discuss a plan that suits your business need
            </h2>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}><button className={styles.button1}>Sign up to get free 100 units for 1 week </button></Link>

            <div className={styles.pageLine}>
                <div class={styles.line}></div>
                <div class={styles.line}></div>
            </div>

            <div className={styles.pageTwo}>
                <div className={styles.pageTwoColA}>
                    <div className={styles.divt}>
                        <h3>Unlimited users/contacts</h3>
                        <img src={UnlimitedUsers} alt="unlimited_users" />
                    </div>
                    <div className={styles.divt}>
                        <h3>Better Quote than competitors</h3>
                        <img src={BetterQuote} alt="better_quote" />
                    </div>
                    <div className={styles.divt}>
                        <h3>Free Sign Up, no payment needed</h3>
                        <img src={SignUp} alt="sign_up" />
                    </div>
                </div>
                <div className={styles.pageTwoColB}>
                    <div className={styles.divt}>
                        <h3>Voice OTP</h3>
                        <img src={VoiceOTP} alt="voice_otp" />
                    </div>

                    <div className={styles.divt}>
                        <h3>Lightning fast delivery</h3>
                        <img src={LightningDelivery} alt="lightning_delivery" />
                    </div>

                    <div className={styles.divt}>
                        <h3>Responsive Customer Support</h3>
                        <img src={CustomerSupport} alt="customer_support" />
                    </div>
                </div>
            </div>
            <button className={styles.lastButton}>
                CHAT US
            </button>

        </div >
    );
}