import styles from "./css/health.module.css";
import Helmet from "react-helmet";
import FemaleDoctor from "../assets/female_doctor.png";
import Dr from "../assets/dr.jfif";
import Iphone from "../assets/ip3.png";
import Gymbae from "../assets/gymbae.png";
import BasketBaller from "../assets/basketballer.png";
import { useContext } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";



export const Health = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Health Sector - ETC Bulk SMS</title>
            </Helmet>

            <div className={styles.menuOne}>
                <div className={styles.menuOneBody}>
                    <h1>
                        HEALTH
                        <span style={{ color: "#1972F9" }}> SECTOR</span>
                    </h1>

                    <p>
                        Empower Your Health Services with SMS Marketing. Enhance Patient Engagement and Care with Simple SMS.<br /><br /> SMS marketing offers valuable support to the health sector, from patient communication to health education and awareness campaigns. By leveraging SMS marketing responsibly, healthcare providers can improve patient engagement, communication, and overall healthcare outcomes.
                    </p>

                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button> Try it now </button>
                    </Link>
                </div>
                <div className={styles.menuOneImage}>
                    <img src={FemaleDoctor} alt="female_doctor" />
                </div>
            </div>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.labButtonMobiles}>Try it now</button>
            </Link>

            <div className={styles.menuTwo}>
                <h1>
                    HOW DOCTORS <span style={{ color: "#1972F9" }}>AND HEALTH CARE CENTERS CAN LEVERAGE SMS MARKETING</span>
                </h1>

                <div className={styles.menuTwoRow}>
                    <div className={styles.mtrContent}>
                        <p>
                            <b>Appointment Reminders:</b> Reduce no-shows and optimize scheduling by sending SMS reminders to patients about upcoming appointments, tests, or follow-up visits.
                        </p>
                        <p>
                            <b>Medication Reminders:</b> Improve medication adherence and patient outcomes by providing timely reminders for patients to take their medications as prescribed.
                        </p>
                        <p>
                            <b>Health Education:</b> Empower patients to take control of their health with SMS messages containing health tips, preventive measures, and information about managing chronic conditions.
                        </p>
                        <p>
                            <b>Test Results Notification:</b> Notify patients when their test results are ready via SMS, providing instructions for accessing results securely through a patient portal or guidance on follow-up steps.
                        </p>
                        <div className={styles.contentImage}>
                            <img src={Dr} alt="dr" />
                        </div>
                    </div>
                    <div className={styles.mtrImage}>
                        <img src={Iphone} alt="iphone" />
                    </div>
                </div>
            </div>

            <div className={styles.cardOne}>
                <div className={styles.cardImage}>
                    <img src={Gymbae} alt="gymbae" />
                    <div className={styles.rect}></div>
                </div>
                <div className={styles.cardContent}>
                    <h1>GYM & FITNESS</h1>
                    <p>
                        <b>Membership Offers:</b> Send SMS messages promoting membership specials, discounts, and limited-time offers to encourage new sign-ups and membership renewals.
                    </p>
                    <p>
                        <b>Class Reminders:</b> Send SMS reminders to members about upcoming fitness classes, personal training sessions, and group workouts.
                    </p>
                    <p>
                        <b>Class Updates and Cancellations:</b> In case of changes or cancellations in the class schedule, promptly inform members through SMS messages to avoid confusion.
                    </p>
                    <p>
                        <b>Personalized Workout Tips:</b> Share personalized workout tips, exercise routines, and nutrition advice to help members achieve their fitness goals.
                    </p>

                    <p>
                        <b>Goal Tracking:</b> Implement SMS-based goal tracking where members can receive regular updates and tips related to their fitness objectives, such as weight loss or muscle gain
                    </p>
                </div>
            </div>

            <div className={styles.cardTwo}>
                <div className={styles.cardTwoContent}>
                    <h1>SPORT & COACHES</h1>
                    <p>
                        <b>Game Reminders:</b> Send SMS alerts to fans and attendees to remind them about upcoming games, matches, or events involving the team.
                    </p>
                    <p>
                        <b>Live Updates: </b> Provide real-time score updates, play-by-play summaries, and key moments during games to keep fans who are unable to attend informed.
                    </p>
                    <p>
                        <b>Exclusive Content:</b> Send SMS messages with exclusive content such as behind-the-scenes videos, player interviews, and sneak peeks into team practices.
                    </p>
                    <p>
                        <b>Player Profiles: </b> Share player profiles, stats, and interesting facts via SMS to help fans get to know the athletes better.
                    </p>
                </div>
                <div className={styles.cardTwoImage}>
                    <img src={BasketBaller} alt="basketballer" />
                    <div className={styles.rect}></div>
                </div>
            </div>

            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.healthButton}>Get Started</button>
            </Link>
        </div >
    );

}