import axios from "axios";

const baseURL = "http://127.0.0.1:8000/api"


export async function POST(endpoint, body, header) {
    const url = baseURL + endpoint;

    try {
        const res = await axios.post(url, body, header)
        return res
    }
    catch (error) {
        return error.response;

        // if (error.response.status === 401) {
        //     // window.location.assign("/Login")
        // }
        // else {
        //     return error.response;
        // }
    }

}
export async function POST_SERVICE(endpoint, body) {
    const url = baseURL + endpoint;
    const token = sessionStorage.getItem("token")
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    try {
        const res = await axios.post(url, body, headers)


        return res


    }
    catch (error) {
        if (error.response.status === 401) {

            window.location.assign("/Login")
        }
        else {
            return error.response;
        }
    }
}
export async function GET(endpoint) {
    const url = baseURL + endpoint;

    try {
        return await axios.get(url)
    }
    catch (error) {
        if (error.response.status === 401) {
            // window.addEventListener("click", 
            // login()
            // )
            window.location.assign("/Login")
        }
        else {
            return error.response;
        }
    }

}
export async function GET_SERVICE(endpoint) {

    const url = baseURL + endpoint;
    const token = sessionStorage.getItem("token")
    const headers = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    try {
        const response = await axios.get(url, headers)


        return response
    }
    catch (error) {

        if (error.response.status === 401) {

            window.location.assign("/Login")
        }
        else {
            return error.response;
        }



    }

}