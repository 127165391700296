import styles from "./css/commerce.module.css";
import ShopCart from "../assets/shopcart.png";
import Hiphone from "../assets/hiphone.png";
import Marketer from "../assets/mkter.png";
import Bike from "../assets/bike.png";
import Handover from "../assets/handover.png";
import Women from "../assets/women.png";
import Date from "../assets/date.png";
import One from "../assets/one.png";
import Two from "../assets/two.png";
import Three from "../assets/three.png";
import { useContext } from "react";
import { AppContext } from "../App";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";


export const Commerce = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Commercial Sector - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.headerOne}>
                <h1>
                    <span style={{ color: "#1972F9" }}>Take your</span> E-COMMERCE <span style={{ color: "#1972F9" }}>and</span> other BUSINESS <span style={{ color: "#1972F9" }}>to the next Level with BULK SMS</span>
                </h1>
            </div>
            <div className={styles.pageOneRow}>
                <div className={styles.rowContent}>
                    <p>
                        People can learn more about your online product and services when you use SMS marketing to get the word out. At ETC, our  SMS marketing service provides an efficient and cost-effective way to help your businesses stay ahead in the game.
                        <br /><br />
                        With our SMS Marketing platform, you can:<br />
                        · Keep your customers informed about new products and services <br />
                        · Use business texting to offer discounts and exclusive offers <br />
                        · Get feedbacks from your customers
                    </p>
                    <div className={styles.contentImage}>
                        <img src={ShopCart} alt="shopcart" />
                    </div>
                </div>
                <div className={styles.rowImage}>
                    <img src={Hiphone} alt="hiphone" />
                </div>
            </div>
            <div className={styles.cardOne}>
                <h1>
                    <span style={{ color: "#1972F9" }}>DIFFERENT USES FOR</span> ECOMMERCE <span style={{ color: "#1972F9" }}> SMS MARKETING</span>
                </h1>
                <p>
                    Adapt and Thrive in Today's Business Landscape
                    <br /><br />
                    The ecommerce business landscape is evolving rapidly, and staying in constant communication with your customers is essential for survival. Here's how our SMS marketing platform can help meet your business needs:
                </p>

                <div className={styles.cardRow}>
                    <div className={styles.cardContent}>
                        <ul className={styles.cardList}>
                            <li> Advertise Your Business: Reach potential customers with targeted advertisements and promotions delivered right to their mobile phones. </li>
                            <li>Drive Traffic to Your Website: Direct customers to your website to browse products, make purchases, and stay updated on your latest offerings</li>
                            <li>Provide Timely Updates: Keep customers informed about changes to your business hours, location, and upcoming events to enhance their experience and satisfaction. </li>
                        </ul>
                    </div>
                    <div className={styles.cardImage}>
                        <img src={Marketer} alt="mkter" />
                    </div>
                </div>
            </div>
            <div className={styles.cardTwo}>
                <div className={styles.cardTwoImage}>
                    <img src={Bike} alt="bike" />
                </div>

                <div className={styles.cardTwoContent}>
                    <h1>
                        LOGISTICS <span style={{ color: "#1972F9" }}>AND</span> DELIVERY <span style={{ color: "#1972F9" }}>SERVICES</span>
                    </h1>
                    <p>
                        Enhance Communication and Efficiency <br /> <br />
                        For logistics and delivery services, SMS marketing can streamline communication, improve efficiency, and enhance customer engagement. Here's how:
                    </p>
                    <ul className={styles.cardTwoList}>
                        <li>Order Confirmations and Updates: Send order confirmations, updates, and tracking information to keep customers informed every step of the way.</li>
                        <li>Delivery Notifications: Notify customers when their orders are out for delivery and provide real-time updates on delivery status for a seamless experience.</li>
                        <li>Delivery reminders</li>
                        <li>Feedback and Reviews: Gather feedback and reviews from customers to improve service quality and build trust and credibility. </li>
                    </ul>
                </div>
            </div>
            <div className={styles.cardThree}>
                <div className={styles.cardThreeContent}>
                    <h1>
                        REAL <span style={{ color: "#1972F9" }}>ESTATE</span>
                    </h1>
                    <p>
                        Drive Conversions and Foster Relationships<br /><br />
                        SMS marketing is a powerful tool for real estate businesses to engage with potential clients, provide valuable information, and drive conversions. Here's how we support real estate businesses:
                    </p>
                    <ul className={styles.cardThreeList}>
                        <li>Property Listings and Updates: Share property listings, updates, and exclusive deals with potential buyers to keep them engaged and informed.</li>
                        <li>Open House Reminders: Send reminders for open houses and property viewings to increase attendance and maximize exposure for your listings.</li>
                        <li>Exclusive Offers: Offer exclusive deals and incentives to potential buyers to encourage inquiries and drive conversions. </li>
                    </ul>
                </div>
                <div className={styles.cardThreeImage}>
                    <img src={Handover} alt="handover" />
                </div>
            </div>

            <div className={styles.cardFour}>
                <div className={styles.cardFourImage}>
                    <img src={Women} alt="women" />
                </div>
                <div className={styles.cardFourContent}>
                    <h1>
                        RETAIL <span style={{ color: "#1972F9" }}>STORES</span>
                    </h1>
                    <p>
                        Boost Sales and Build Brand Loyalty<br /><br />
                        SMS marketing can drive sales, enhance brand loyalty, and improve customer engagement for retail businesses. Here's how we support retail stores:
                    </p>
                    <ul className={styles.cardFourList}>
                        <li>Promotions and Discounts: Send promotions, discounts, and special offers directly to customers' phones to drive sales and encourage repeat purchases.</li>
                        <li>Flash Sales and Alerts: Alert customers about flash sales, limited-time offers, and inventory updates to create urgency and drive immediate action.</li>
                        <li>Personalized Recommendations: Provide personalized product recommendations based on customers' purchase history and preferences to enhance the shopping experience. </li>
                    </ul>
                </div>
            </div>
            <div className={styles.cardFive}>
                <div className={styles.cardFiveContent}>
                    <h1>
                        RESTAURANT AND BARS
                    </h1>
                    <p>
                        Increase Foot Traffic and Boost Revenue <br /><br /> SMS marketing is a valuable tool for restaurants and bars to engage with customers, promote special offers, and increase foot traffic. Here's how we support restaurant and bars:
                    </p>
                    <ul className={styles.cardFiveList}>
                        <li>Feedback and Surveys: Gather feedback and reviews from customers to improve service quality and customer satisfaction.</li>
                        <li>Menu Updates: Share updates on menu items, new dishes, and special promotions to entice customers and drive sales.</li>
                        <li>Event Announcements: Promote upcoming events, live music performances, and special offers to attract customers and increase foot traffic. </li>
                    </ul>
                </div>
                <div className={styles.cardFiveImage}>
                    <img src={Date} alt="date" />
                </div>
            </div>
            <div className={styles.headerTwo}>
                <h1>
                    <span style={{ color: "#1972F9" }}>PUT</span> BULK SMS <span style={{ color: "#1972F9" }}>TO WORK FOR YOU</span>
                </h1>
                <p>
                    <b>Grow your reach and create memorable experiences:</b> With SMS marketing, businesses can effectively reach customers almost anywhere, connect through meaningful conversations, and create memorable experiences that resonate with customers. Here's how:
                </p>
            </div>
            <div className={styles.cardSix}>
                <div className={styles.miniCard}>
                    <div className={styles.miniCardHeader}>
                        <img src={Three} alt="three" />
                        <h3>Reach customers anywhere</h3>
                    </div>
                    <p>
                        Leverage the ubiquity and convenience of mobile devices to reach customers almost anywhere, anytime.
                    </p>
                </div>
                <div className={styles.miniCard}>
                    <div className={styles.miniCardHeader}>
                        <img src={Two} alt="two" />
                        <h3>Connect through conversations</h3>
                    </div>
                    <p>
                        Facilitate personalized interactions and real-time communication between businesses and customers for enhanced engagement and satisfaction.
                    </p>
                </div>
                <div className={styles.miniCard}>
                    <div className={styles.miniCardHeader}>
                        <img src={One} alt="one" />
                        <h3>Create memorable
                            experiences</h3>
                    </div>
                    <p>
                        Deliver personalized, timely, and valuable interactions that leave a lasting impression on customers and drive loyalty and advocacy.
                    </p>
                </div>
            </div>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.commButton}>SIGN UP</button>
            </Link>
        </div>
    );
}