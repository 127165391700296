import styles from "./css/signup.module.css";
import style from "./css/signups.module.css";
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Login } from "./Login";
import WhyChoose from "../assets/whychoose.png";
import { useContext } from "react";
import { AppContext } from "../App";
import Helmet from "react-helmet";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { POST } from "../services/backend";
import ErrorNotification from "./ErrorNotification";
import SuccessNotification from "./SuccessNotification";


export const Signup = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [user, setUser] = useState({});
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");
    const [industry, setIndustry] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [displayError, setDisplayError] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    setShowHead(true);
    setShowFoot(true);
    const navigate = useNavigate();
    const passwordVisibility = () => setPasswordVisible(!passwordVisible);
    const confirmVisibility = () => setConfirmVisible(!confirmVisible);


    // function handleCallbackResponse(response) {
    //     // console.log("Encoded JWT ID Token = " + response.credential);
    //     var userObject = jwtDecode(response.credential);
    //     console.log(userObject);
    //     setUser(userObject);
    //     sessionStorage.setItem("token", user.jti);
    //     const token = sessionStorage.getItem("token");
    //     console.log(token);
    //     token && navigate("/Dashboard");
    // }

    const handleRegister = async (e) => {
        e.preventDefault(); //prevents browser reload

        if (name !== "" || email !== "" || password !== "" || confirmPassword !== "") {
            const body = {
                "name": name,
                "email": email,
                "password": password,
                "confirmPassword": confirmPassword,
                "phone_number": phone,
                "company": company,
                "industry": industry
            }
            if (password === confirmPassword) {
                // console.log(body);
                const response = await POST('/register', body)
                if (response) {
                    if (response.data.code === 200) {
                        setDisplaySuccess(true);
                        setSuccessMessage('Account created successfully. Please check your mail to verify your account.');

                        setTimeout(() => {
                            window.location.reload(false);
                        }, 1500);
                    }
                    else {
                        setDisplayError(true)
                        setError(response.data.message)
                        setTimeout(function () {
                            setDisplayError(false)
                        }, 1500)

                    }
                } else {
                    setDisplayError(true);
                    setError("No response from server")
                    setTimeout(function () {
                        setDisplayError(false)
                    }, 1500)
                }

            }
            else {
                setDisplayError(true)
                setError("Passwords do not match")
                setTimeout(function () {
                    setDisplayError(false)
                }, 1500)
            }
        }
        else {
            setDisplayError(true)
            setError("Fields cannot be empty")
            setTimeout(function () {
                setDisplayError(false)
                setError("")
            }, 1500)
        }

    }


    // useEffect(() => {
    //     /* global google */
    //     google.accounts.id.initialize({
    //         client_id:
    //             "118164385838-1l0ehegg9fgdfv26re8eea4b4tlsbtdc.apps.googleusercontent.com",
    //         callback: handleCallbackResponse
    //     })
    //     google.accounts.id.renderButton(document.getElementById("signinButton"), { theme: "outline", size: "large" })

    //     google.accounts.id.prompt()
    // }, [])

    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors },
    // } = useForm({
    //     resolver: yupResolver(schema),
    // });
    return (
        <div className={styles.containerB}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sign In - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.cardB}>
                <ErrorNotification open={displayError} content={error} />
                <SuccessNotification open={displaySuccess} content={successMessage} />
                <div className={styles.cardBContent}>
                    <h3>Create your account</h3>
                    <p>
                        Get started by creating your account to enjoy our fast, easy and fantastic <span style={{ color: "#1972F9" }}>Bulk SMS service</span>
                    </p>
                    <div className={styles.cardBImage}>
                        <img src={WhyChoose} alt="whychoose" />
                    </div>
                </div>
                <div className={styles.cardBForm}>
                    <form className={style.form} onSubmit={(e) => handleRegister(e)}>
                        <p style={{ fontWeight: 600 }}>
                            Required fields are outlined in <span style={{ color: '#fa7575' }}> red</span>
                        </p>
                        <label className={styles.required}>
                            <h4>Enter Full Name:</h4>
                        </label>
                        <input type="text" className={styles.required} name="name" value={name} onChange={(e) => setName(e.target.value)} required />

                        <label className={styles.required}>
                            <h4>Enter Email Address:</h4>
                        </label>
                        <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />

                        <label className={styles.required}>
                            <h4>Enter Passsword:</h4>
                        </label>
                        <div className={styles.passwordInput}>
                            <input style={{ padding: 0, margin: 0 }} className={styles.passwordInputText} type={passwordVisible ? "text" : "password"} name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <i onClick={passwordVisibility} className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </div>

                        <label className={styles.required}>
                            <h4>Confirm Passsword:</h4>
                        </label>
                        <div className={styles.passwordInput}>
                            <input style={{ padding: 0, margin: 0 }} className={styles.passwordInputText} type={confirmVisible ? "text" : "password"} name="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                            <i onClick={confirmVisibility} className={confirmVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </div>

                        <label className={styles.requir}>
                            <h4>Enter Phone Number</h4>
                        </label>
                        <input type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />

                        <label className={styles.requir}>
                            <h4>Enter Company or Business name</h4>
                        </label>
                        <input style={{ border: "1px solid #000000" }} type="text" name="company" value={company} onChange={(e) => setCompany(e.target.value)} />

                        <label className={styles.requir}>
                            <h4>Enter Company Industry</h4>
                        </label>
                        <input style={{ border: "1px solid #000000" }} type="text" name="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} />

                        <input type="submit" value="SIGN UP" />
                    </form>

                    <button className={styles.googleButton}>
                        <i style={{ marginRight: "1rem" }} className="fa-brands fa-google fa-lg"></i>
                        Sign in with Google

                    </button>
                    {/* <div style={{ marginTop: "0.5rem", width: "95%", maxWidth: "95%", marginBottom: "1rem" }} id="signinButton"></div> */}

                    <p className={styles.lastP}>
                        By clicking on the Sign Up button, it means you have read and agreed to our <span style={{ color: "#1972F9" }}>Terms of Service</span> and <span style={{ color: "#1972F9" }}>Privacy Policy.</span> <br />
                        <span style={{ color: "#1972F9" }}> Already have an account? <Link className={styles.textLink} to="/Login" element={<Login />}><b>Login</b></Link></span>
                    </p>
                </div>
            </div>
        </div>
    );
}