import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isLoggedIn, children }) => {
    const token = sessionStorage.getItem("token");
    if (token === undefined) {
        return <Navigate to="/Login" />;
    }

    return children;
};
export default ProtectedRoute;