import './App.css';
import { Homepage } from './components/Landing/Homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbars } from './common/Navbars';
import { Footer } from './common/Footer';
import { Solution } from './components/Header/Solution';
import { Political } from './pages/Political';
import { Pricing } from './pages/Pricing';
import { Health } from './pages/Health';
import { Finance } from './pages/Finance';
import { Religious } from './pages/Religious';
import { Contact } from './pages/Contact';
import { useState, useEffect, createContext } from 'react';
import { Commerce } from './pages/Commerce';
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';
import { Developers } from './pages/Developers';
import ProtectedRoute from './routes/ProtectedRoute';
import { Dashboard } from './pages/Dashboard';
import SuccessNotification from './pages/SuccessNotification';
import ScrollToTop from './components/ScrollToTop';


export const AppContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [showFoot, setShowFoot] = useState(true);
  const [showHead, setShowHead] = useState(true);
  const [state, setState] = useState('');

  useEffect(() => {
    const token = sessionStorage.getItem("token")
    if (token !== undefined) {
      setIsLoggedIn(true)
    }
    else {
      isLoggedIn(false)
    }
  }, [])

  return (
    <div className='App'>
      <AppContext.Provider value={
        {
          value: [showFoot, setShowFoot],
          value3: [showHead, setShowHead],
          value4: [state, setState]
        }
      }>
        <Router>
          <ScrollToTop />
          {showHead && <Navbars />}
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/Solution' element={<Solution />} />
            <Route path='/Pricing' element={<Pricing />} />
            <Route path='/Political' element={<Political />} />
            <Route path='/Health' element={<Health />} />
            <Route path='/Finance' element={<Finance />} />
            <Route path='/Religious' element={<Religious />} />
            <Route path='/ContactUs' element={<Contact />} />
            <Route path='/Ecommerce' element={<Commerce />} />
            <Route path='/Login' element={<Login />} />
            <Route path='/Signup' element={<Signup />} />
            <Route path="/Dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
            <Route path='/Developers' element={<Developers />} />
            {/*
            <Route path="/CreateSMS" element={<ProtectedRoute> <CreateSms /> </ProtectedRoute>} />
            */}
          </Routes>
          {showFoot && <Footer />}
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
