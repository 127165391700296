import styles from "./css/solution.module.css";
import Chat from "../../assets/message.svg";
import CityGirl from "../../assets/citygirl.png";
import Background from "../../assets/background.svg";
import Chart from "../../assets/chart.svg";
import Chat1 from "../../assets/chat1.png";
import Chat2 from "../../assets/chat2.png";
import Chat3 from "../../assets/chat3.png";
import AssetA from "../../assets/asset_a.png";
import AssetB from "../../assets/asset_b.png";
import Helmet from "react-helmet";
import { useContext } from "react";
import { AppContext } from "../../App";
import { Signup } from "../../pages/Signup";
import { Link } from "react-router-dom";
import { useState } from "react";


export const Solution = () => {
    const [isSupport, setIsSupport] = useState(false);
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    const [hover, setHover] = useState(false);
    const [hove, setHove] = useState(false);
    const [hov, setHov] = useState(false);

    const Toggle = () => {
        setIsSupport(!isSupport);
    }

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Solution - ETC Bulk SMS</title>
            </Helmet>

            <div className={styles.menuOne}>
                <div className={styles.menuOneContent}>
                    <div className={styles.roow}>
                        <img src={Chat} alt="chat"></img>
                        <h1> <span style={{ color: "#1972f9" }}>BULK SMS</span> <br /> SERVICE</h1>
                    </div>
                    <p>
                        Unleash the Power of Direct Communication. <br />
                        Engage, inform, and convert like never before! Elevate your marketing strategy with the instantaneous impact of SMS.
                    </p>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.button1}> Get started </button>
                    </Link>
                </div>
                <div className={styles.menuOneImage}>
                    <img src={CityGirl} alt="chat"></img>
                </div>
            </div>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.labButtonMobiles}>Get Started</button>
            </Link>

            <div className={styles.cardOne}>
                <h1><span style={{ color: "#1972F9" }}>Experience Real Results with our</span> BULK SMS Service</h1>
                <div className={styles.test}>
                    Craft the perfect message in advance and schedule delivery to your contacts or groups in just a few clicks.
                </div>
                <div className={styles.bigRow}>
                    <div onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)} className={styles.rowCard}>
                        <div className={styles.imageRow}>
                            <img src={Chat1} alt="chat1"></img>
                            <h3>Reach Your Target Audience with Precision </h3>
                        </div>
                        {hover && <p>
                            Unlock the potential of strategic SMS marketing to seamlessly connect with your ideal audience, ignite engagement, and achieve your marketing objectives. Deliver Messages Directly to Their Fingertips. SMS messages are whisked straight to recipients' mobile phones, ensuring your message lands with speed and precision, no inbox clutter in sight.
                        </p>}
                    </div>

                    <div onMouseEnter={() => setHove(true)}
                        onMouseLeave={() => setHove(false)} className={styles.rowCar}>
                        <div className={styles.imageRow}>
                            <img src={Chat2} alt="chat2"></img>
                            <h3>Personalize Your Message for Maximum Impact</h3>
                        </div>

                        {hove && <p>
                            Tap into the personal touch of SMS marketing, addressing recipients by name and tailoring messages to their preferences, creating connections that resonate.
                        </p>}
                    </div>

                    <div onMouseEnter={() => setHov(true)}
                        onMouseLeave={() => setHov(false)} className={styles.rowCa}>
                        <div className={styles.imageRow}>
                            <img src={Chat3} alt="chat3"></img>
                            <h3>Diversify Your Campaigns, Multiply Your Impact </h3>
                        </div>
                        {hov && <p>
                            Segment and conquer with tailored campaigns, reaching specific demographics, locations, and behaviors to amplify engagement and drive action.
                            Harness the Power of Location-Based Marketing. Leverage the immediacy of location-based SMS marketing, sending timely offers and alerts when customers are in the vicinity of your store or event.
                        </p>}
                    </div>
                </div>

                <div className={styles.lastRow}>
                    <div className={styles.lastRow1}>
                        <h3>Supercharge Your<span style={{ color: "#122EBF" }}> Sales </span>Potential</h3>
                        <p>
                            Ignite sales with the dynamic force of SMS marketing, sparking instant engagement, fueling excitement with exclusive offers, and nurturing lasting customer relationships. <br />
                            Explore endless possibilities, including:
                        </p>
                        <ul className={styles.styledList}>
                            <li>Captivating promotions</li>
                            <li>Time-sensitive deals</li>
                        </ul>
                        <img src={AssetA} alt="asset_a"></img>
                    </div>
                    <div className={styles.lastRow2}>
                        <img src={AssetB} alt="asset_b"></img>

                        <ul className={styles.list}>
                            <li>Buzzworthy product launches</li>
                            <li>Sneak peek previews</li>
                            <li>Seamless mobile purchases</li>
                            <li>Valuable feedback loops</li>
                            <li>VIP perks and privileges</li>
                            <li>Persuasive calls-to-action</li>
                        </ul>
                    </div>
                </div>


            </div>

            <div className={styles.banner}>
                <img src={Background} alt="background"></img>
            </div>
            <div className={styles.bannerTwo}>
                <h2>Master
                    <span style={{ color: "#1972F9" }}> Sending BULK SMS</span>
                </h2>
                <p>
                    Learn the ropes and unleash your messaging prowess with our user-friendly platform, empowering you to connect with customers effortlessly.
                </p>
            </div>
            <div className={styles.bannerThree}>
                <img src={Chart} alt="chart"></img>
            </div>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}><button className={styles.blackButton}>Send your first bulk sms</button></Link>
        </div >
    );
}