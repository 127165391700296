import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import { Signup } from './Signup';
import { Login } from './Login';



export function Button() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "20vw",
            justifyContent: "flex-end"
        }}>
            <Link to="/Login" style={{
                paddingRight: "2rem",
                whiteSpace: "nowrap"
            }} element={<Login />}>
                <button className='btn'>Log in</button>
            </Link>
            <Link to="/Signup" style={{
                paddingRight: "2rem",
                whiteSpace: "nowrap"
            }} element={<Signup />}>
                <button className='btns'>Sign Up</button>
            </Link>
        </div>
    );



}