import styles from './css/success.module.css';

export function Successs({ isOpen, onClose, children }) {
    return (
        <>
            {isOpen ? (
                <div className={styles.overlay}>
                    <div className={styles.overlay_background} onClick={onClose} />
                    <div className={styles.overlay_container}>
                        {children}

                    </div>
                </div>
            ) : null}
        </>
    )

}