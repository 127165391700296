import React, { useState, useEffect } from 'react';
import { Button } from '../pages/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from '../pages/Dropdown';
import { Solution } from '../components/Header/Solution';
import { Pricing } from '../pages/Pricing';
import { Developers } from '../pages/Developers';
import { Contact } from '../pages/Contact';
import Minidrop from '../pages/Minidrop';


export function Navbars() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [minidrop, setMinidrop] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const dropNow = () => setDropdown(!dropNow);
    const closeDrop = () => setDropdown(false);
    const mini = () => setMinidrop(!minidrop);
    const closeMini = () => setMinidrop(false);

    const handleResize = () => window.innerWidth > 720 && setMinidrop(false);

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    return (
        <>
            <nav className='navbar'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to="/Solution" element={<Solution />} className='nav-links' onClick={closeMobileMenu}>
                            Solution
                        </Link>
                    </li>
                    <li
                        className='nav-item'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onClick={dropNow}
                    >
                        <Link
                            className='nav-links'
                            onClick={
                                window.innerWidth < 960 ? mini :
                                    closeMobileMenu
                            }
                        >
                            Industry
                        </Link>
                        {dropdown && <Dropdown />}
                        {minidrop && <Minidrop onClick={closeMobileMenu} onClicks={closeMini} />}
                    </li>
                    <li className='nav-item'>
                        <Link
                            to="/Pricing" element={<Pricing />}
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Pricing
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to="/Developers" element={<Developers />}
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Developers
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            to="/ContactUs" element={<Contact />}
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to='/Login'
                            className='nav-links-mobileOne'
                            onClick={closeMobileMenu}
                        >
                            Log in
                        </Link>
                    </li>
                    <li>
                        <Link
                            to='/Signup'
                            className='nav-links-mobileTwo'
                            onClick={closeMobileMenu}
                        >
                            Sign Up
                        </Link>
                    </li>
                </ul>
                <Button />
            </nav>
        </>
    );
}

// export default Navbars;