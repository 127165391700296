import Helmet from 'react-helmet';
import { useContext } from 'react';
import { AppContext } from '../../App';
import { useState } from 'react';
import People from '../../assets/people.svg';
import Man from '../../assets/man.png';
import Hand from '../../assets/hand.svg';
import Charts from '../../assets/chartss.svg';
import Abs1 from '../../assets/abstract1.png';
import Abs2 from '../../assets/abstract2.png';
import Abs3 from '../../assets/abstract3.png';
import Phon from '../../assets/iphone1.png';
import Woman from '../../assets/womanphone.png';
import styles from './css/home.module.css';
import { Link } from 'react-router-dom';
import { Signup } from '../../pages/Signup';


export const Homepage = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    const [isSupport, setIsSupport] = useState(false);
    setShowHead(true);
    setShowFoot(true);

    const [hover, setHover] = useState(false);
    const [hove, setHove] = useState(false);
    const [hov, setHov] = useState(false);
    // const onHover = () => {
    //     setHover(true);
    // };

    // const onLeave = () => {
    //     setHover(false);
    // };

    const Toggle = () => {
        setIsSupport(!isSupport);
    }

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.menuItem}>
                <div className={styles.homePageOneContent}>
                    <h1>Reach users effortlessly with <span style={{ color: "#1972F9" }}>BULK SMS</span></h1>
                    <p>
                        From real estate to healthcare and to every other sector, ETC Bulk SMS helps individuals, and businesses communicate quickly and affordably
                    </p>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.labButton2}>Get a FREE account</button>
                    </Link>
                </div>
                <div className={styles.homePageOneImage}>
                    <img src={Phon} alt="phon"></img>
                </div>
                <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                    <button className={styles.labButtonMobile}>Get a FREE account</button>
                </Link>
            </div>

            <div className={styles.menuu}>
                <h1>Our Features</h1>
                <p>Everything you need to engage with your audience using ETC Bulk SMS platform</p>

                <div className={styles.menuIte}>
                    <div className={styles.menuImage}>
                        <img src={People} alt="people"></img>
                    </div>
                    <div className={styles.menuContent}>
                        <h2>Easy Templates</h2>
                        <p>
                            Save time with customizable templates. Create your own or use our ready-made options for instant messaging.
                        </p>
                        <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                            <button className={styles.labButton3}> Get Started </button>
                        </Link>
                    </div>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.labButtonMobiles}>Get Started</button>
                    </Link>
                </div>
            </div>

            <div className={styles.menuTwo}>
                <div className={styles.menuTwoContent}>
                    <h2>Mass Texting</h2>
                    <p>
                        Send messages and offers to large groups of people in one click. Promote sales, share updates, and keep customers in the loop effortlessly.
                    </p>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.labButton3}> Get Started </button>
                    </Link>
                </div>
                <div className={styles.menuTwoImage}>
                    <img src={Man} alt="man"></img>
                </div>
            </div>

            <div className={styles.menuThree}>
                <div className={styles.menuThreeImage}>
                    <img src={Hand} alt="hand"></img>
                </div>

                <div className={styles.menuThreeContent}>
                    <h2>Schedule messages</h2>
                    <p>
                        Plan ahead by scheduling messages. Set it up once and let our system do the rest, saving you time and hassle.
                    </p>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.labButton3}> Get Started </button>
                    </Link>
                </div>
                <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                    <button className={styles.labButtonMobiles}>Get Started</button>
                </Link>
            </div>

            <div className={styles.menuFour}>
                <div className={styles.menuFourContent}>
                    <h2>Unlimited Contacts</h2>
                    <p>
                        Grow your contact list without limits. Import existing contacts or add new ones easily, with customizable fields.
                    </p>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.labButton3}> Get Started </button>
                    </Link>
                </div>

                <div className={styles.menuFourImage}>
                    <img src={Charts} alt="phon"></img>
                </div>
            </div>
            <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                <button className={styles.labButtonMobiles}>Get Started</button>
            </Link>

            <div className={styles.headerOne}>
                <h1>Discover More Features</h1>
            </div>

            <div className={styles.menuFive}>
                <div onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)} className={styles.miniMenu}>
                    <h3>Picture messaging</h3>
                    <div className={styles.miniMenuImage}>
                        <img src={Abs1} alt="abstract1"></img>
                    </div>
                    {hover && <p>
                        Add visuals to your messages for more engagement. Share product photos or event flyers with ease
                    </p>}
                </div>

                <div onMouseEnter={() => setHove(true)}
                    onMouseLeave={() => setHove(false)} className={styles.miniMenu}>
                    <h3>Track Subscriptions</h3>

                    <div className={styles.miniMenuImage}>
                        <img src={Abs2} alt="abstract2"></img>
                    </div>
                    {hove && <p>
                        See who's joining your list with detailed campaign results. Use insights to improve your messaging strategy
                    </p>}
                </div>

                <div onMouseEnter={() => setHov(true)}
                    onMouseLeave={() => setHov(false)} className={styles.miniMenu}>
                    <h3>Long Messages</h3>

                    <div className={styles.miniMenuImage}>
                        <img src={Abs3} alt="abstract3"></img>
                    </div>
                    {hov && <p>
                        Say more with longer messages. Keep customers informed without worrying about character limits.
                    </p>}
                </div>
            </div>

            <div className={styles.headerTwo}>
                <h1>Start connecting with your audience today. <br /> Sign up for a <span style={{ color: "#1972f9" }}>7-day-trial</span> and see the difference with ETC Bulk SMS. </h1>
            </div>

            <div className={styles.menuSix}>
                <div className={styles.menuSixContent}>
                    <h2>GET STARTED TODAY</h2>
                    <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                        <button className={styles.button4}> Sign UP </button>
                    </Link>
                </div>
                <div className={styles.menuSixImage}>
                    <img src={Woman} alt="womanphone"></img>
                </div>
            </div>
        </div >

    );
}