import React from 'react';
import styles from "./css/notification.module.css"
import Error from "../assets/error.png"

const ErrorNotification = (props) => {
    return (
        <div className={props.open ? styles.modal : styles.hideModal}>
            <img src={Error} alt="X"></img>
            <div className={styles.content}>
                <h3>Error</h3>
                <p>{props.content} </p>
            </div>
        </div>
    );
};

export default ErrorNotification;