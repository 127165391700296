import React from 'react';
import styles from "./css/dashboard.module.css"

export const Dashboard = () => {
    return (
        <div className={styles.container}>
            <h1 style={{ color: "red" }}>dashboard goes here...</h1>
        </div>
    );
}

