import { useContext } from "react";
import { AppContext } from "../App";
import styles from "./css/developers.module.css";
import Helmet from "react-helmet";

export const Developers = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowFoot(false);
    setShowHead(false);


    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title> For Developers - ETC Bulk SMS</title>
            </Helmet>
            <div className={styles.rowOne}>
                <button>Introduction</button>
                <button>GET</button>
            </div>
            <div className={styles.vr}></div>
            <div className={styles.rowTwo}>
                <h3>Introduction</h3>
                <p>This is the official API documentation for ETC. Below you will find a current list of the available methods for ETC. <br /> <br />
                    NOTE: You will need to create a free account to use the API. You can register here.
                </p>
            </div>

        </div>
    );

}