export const MenuItems = [
    {
        title: 'Political',
        path: '/Political',
        cName: 'dropdown-link'
    },
    {
        title: 'E-Commerce & Businesses',
        path: '/Ecommerce',
        cName: 'dropdown-link'
    },
    {
        title: 'Religious Centres',
        path: '/Religious',
        cName: 'dropdown-link'
    },
    {
        title: 'Financial Sector',
        path: '/Finance',
        cName: 'dropdown-link'
    },
    {
        title: 'Health Sector',
        path: '/Health',
        cName: 'dropdown-link'
    }
];