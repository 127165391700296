import React from 'react';
import styles from "./css/notification.module.css"
import Success from "../assets/success.png";

const SuccessNotification = (props) => {
    return (
        <div className={props.open ? styles.modalSuccess : styles.hideModal}>
            <img src={Success} alt="X"></img>
            <div className={styles.content}>
                <h3>Success</h3>
                <p>{props.content} </p>

            </div>


        </div>
    );
};

export default SuccessNotification;