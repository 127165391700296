import styles from "./css/religious.module.css";
import PrayerWoman from "../assets/praywoman.png";
import Choir from "../assets/choir.png";
import Kite from "../assets/kiten.png";
import { useContext } from "react";
import { AppContext } from "../App";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Signup } from "./Signup";


export const Religious = () => {
    const { value, value2, value3 } = useContext(AppContext);
    const [showFoot, setShowFoot] = value;
    const [showHead, setShowHead] = value3;
    setShowHead(true);
    setShowFoot(true);

    return (
        <div className={styles.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Religious Sector - ETC Bulk SMS</title>
            </Helmet>

            <div className={styles.headerOne}>
                <h1>
                    <span style={{ color: "#1972F9" }}>CONNECT</span> DEEPER,<span style={{ color: "#1972F9" }}> SPREAD YOUR MESSAGE WITH </span>BULK SMS
                </h1>
                <p>
                    Empower Your Religious Centre with SMS Marketing
                    <br /><br />SMS marketing is more than just messages; it's a pathway to strengthen connections, nurture spirituality, and foster a sense of community among your members.
                </p>
            </div>

            <div className={styles.cardOne}>
                <div className={styles.cardOneImage}>
                    <img src={PrayerWoman} alt="praywoman" />
                </div>


                <div className={styles.cardOneContent}>
                    <h2>
                        <span style={{ color: "#1972F9" }}>Why Use </span> BULK SMS to win <span style={{ color: "#1972F9" }}>Members</span>
                    </h2>

                    <p>
                        <b>Event Notifications:</b> Ensure your members never miss a moment. Send SMS alerts about upcoming religious services, events, and gatherings, inviting them to participate and share in the spiritual journey.
                    </p>
                    <p>
                        <b>Prayer Reminders:</b> Foster a sense of unity and devotion. Send prayer reminders for specific times or occasions, encouraging active spiritual participation and collective prayer.
                    </p>
                    <p>
                        <b>Sermon Highlights:</b> Keep the spirit alive beyond the pulpit. Share key points, verses, or reflections from sermons via SMS, allowing members to revisit and contemplate the teachings wherever they are.
                    </p>
                    <p>
                        <b>Inspirational Messages:</b> Illuminate the path of faith every day. Send daily or weekly inspirational messages, quotes, or verses that uplift and encourage spiritual growth, nourishing the soul.
                    </p>
                    <p>
                        <b>Community Announcements:</b> Unify your congregation with purpose. Communicate important announcements, volunteer opportunities, and community service initiatives through SMS, fostering engagement and involvement.
                    </p>
                </div>
            </div>

            <div className={styles.cardTwo}>
                <div className={styles.cardTwoContent}>
                    <h1>BULK SMS <span style={{ color: "#1972F9" }}>USE CASES FOR </span>CHURCHES</h1>
                    <ul className={styles.ctcList}>
                        <li>. Prayer requests and reminders</li>
                        <li>. Devotional messages</li>
                        <li>. Financial contributions</li>
                        <li>. Pastoral care and counselling</li>
                        <li>. Announcements and updates</li>
                        <li>. Outreach and invitations</li>
                        <li>. Membership engagement</li>
                        <li>. Educational resources</li>
                    </ul>
                </div>
                <div className={styles.cardTwoImage}>
                    <img src={Choir} alt="choir" />
                </div>
            </div>

            <div className={styles.headerTwo}>
                <h1>
                    SAMPLE MESSAGES <span style={{ color: "#1972F9" }}>FOR CHURCHES</span>
                </h1>
            </div>

            <div className={styles.cardThree}>
                <div className={styles.cardRowA}>
                    <div className={styles.miniRow}>
                        <p>
                            🌟 Join us this Sunday at [Church Name] for a soul-refreshing worship service at [Time]. Let's gather to praise and seek spiritual inspiration together. See you there!
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            Kindly remember [Name] in your prayers as they undergo surgery today. Let's lift them up in faith for a successful procedure and speedy recovery
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            📖 Meditate on today's verse: 'For I know the plans I have for you,' declares the Lord, 'plans to prosper you and not to harm you, plans to give you hope and a future.' - Jeremiah 2011
                        </p>
                    </div>
                </div>

                <div className={styles.cardRowA}>
                    <div className={styles.miniRow}>
                        <p>
                            🙏 Your generosity fuels our mission. Consider contributing to support [Church Name]'s initiatives. To give, visit [Donation Link] or text 'GIVE' to [Donation Number].
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            📢 [Church Name] is excited to announce our upcoming revival week from [Date] to [Date]. Mark your calendars for evenings of worship, teaching, and revival!
                        </p>
                    </div>
                    <div className={styles.miniRow}>
                        <p>
                            🌟 Welcome to our [Church Name] family, [New Member's Name]! We're thrilled to have you join us on this spiritual journey. Let's grow together in faith
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.lastHeader}>
                <Link className={styles.textLink} to="/Signup" element={<Signup />}>
                    <button> Get Started </button>
                </Link>
            </div>
        </div >
    );

}